<div class="landing">
  <div class="section twikit-header">
    <div class="section-text">
      <div class="header-content">
        <div class="header-logo-title">
          <a class="twikit-logo-link" href="/">
            <img src="https://dev-products.twikit.com/assets/images/twikit/logo.svg" />
          </a>
          <p class="mat-display-1">Fighting Corona together</p>
        </div>
        <button class="get-in-touch" [routerLink]="['/']" fragment="getintouch">Get in touch</button>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-text">
      <div class="section-text-product">
        <div class="section-text-product-item margin-right">
          <h1>3D printed face shield</h1>
          <mat-divider></mat-divider>
          <p>This one-size-fits-all face shield consists of a transparent sheet which is fixed in a 3D printed frame.
            <br />This product provides an easy and lightweight solution for an extra layer
            <br />of face protection. The shield locks quickly and easily into the frame.​</p>
          <ul>
            <li>Easy to mount​</li>
            <li>Reusable</li>
            <li>Light weight (29 grams)</li>
            <li>Transparent sheets available at office suppliers</li>
            <li>Advised frame material: PLA</li>
            <li>Advised 3D print technique: FDM printing - Glazing temp 60°C - 3D printing temp 215°C - 0.2 layer height
            </li>
            <li>Advised sheet material: A4 sheet size - PVC material - 200 Micron thickness</li>
          </ul>
          <br />
          <button (click)="downloadProduct('face-shield')">Get download</button>
        </div>
        <div class="section-text-product-item margin-top">
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
        </div>
      </div>
    </div>
  </div>

  <div class="section section-text">
    <h1>More products</h1>
    <mat-divider></mat-divider>
    <p>Choose your product and we will send you an e-mail with your production files and construction instructions.
      <br />These files allow you to create your own DIY face mask or space divider.</p>
    <br />
  </div>
  <div class="section">
    <div class="section-content">
      <div class="section-products">
        <div *ngFor="let key of productKeys" class="product list">
          <mat-card class="product-card">
            <mat-card-title>{{products[key].name}}</mat-card-title>
            <img mat-card-image [src]="products[key].image">
            <button *ngIf="products[key].customizable && !products[key].orderable && !products[key].downloadable"
              routerLink="/product/{{key}}"
              [disabled]="!products[key].available">{{products[key].available ? 'CUSTOMIZE YOUR MASK' : 'COMING SOON' }}</button>
            <button *ngIf="products[key].customizable && products[key].downloadable && !products[key].orderable"
              routerLink="/product/{{key}}"
              [disabled]="!products[key].available">{{products[key].available ? 'CUSTOMIZE AND DOWNLOAD' : 'COMING SOON' }}</button>
            <button *ngIf="!products[key].customizable && products[key].downloadable && !products[key].orderable"
              (click)="downloadProduct(key)"
              [disabled]="!products[key].available">{{products[key].available ? 'GET DOWNLOAD' : 'COMING SOON' }}</button>
            <button *ngIf="products[key].customizable && products[key].orderable" routerLink="/product/{{key}}"
              [disabled]="!products[key].available">{{products[key].available ? 'CUSTOMIZE AND ORDER' : 'COMING SOON' }}</button>
          </mat-card>
          <br />
          <p [innerHTML]="products[key].description"></p>
        </div>
      </div>
    </div>
  </div>
  <!--
  <div class="section twikit-how-to-help">
    <div class="section-content">
      <p>If you have any product ideas, please reach out to us.<br/> We are curious to hear all your innovative ideas!​</p>
      <button (click)="contactTwikit()">Contact us</button>
    </div>
  </div>-->
  <!--<iframe class="google-form" src="https://docs.google.com/forms/d/e/1FAIpQLSeombxGZvFmbNhgdQvYSOtNBom0DXmvpSOcZqADdKa7Vtp9MA/viewform?embedded=true" width="640" height="964" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
-->
  <div id="getintouch" class="section twikit-partners">
    <div class="section">
      <div class="section-text">
        <h1>Get in touch</h1>
        <mat-divider></mat-divider>
        <p>Get in touch for more information or to get in contact with a certified manufacturing partner for 3D printed
          masks.</p>
      </div>
      <div class="section-content">
        <form [formGroup]="joinTheFightForm"
          (ngSubmit)="joinTheFightForm.valid && contactTwikit(joinTheFightForm.value)">
          <div>
            <label for="name">
              Name
            </label>
            <input id="name" type="text" formControlName="name"
              [class.is-invalid]="joinTheFightForm.get('name').invalid && joinTheFightForm.get('name').touched">
          </div>
          <div>
            <label for="email">
              E-mail
            </label>
            <input id="email" type="email" formControlName="email" email
              [class.is-invalid]="joinTheFightForm.get('email').invalid && joinTheFightForm.get('email').touched">
          </div>
          <div>
            <label for="phone">
              Phone
            </label>
            <input id="phone" type="text" formControlName="phone">
          </div>
          <div>
            <label for="message">
              Message
            </label>
            <textarea id="message" type="text" formControlName="message"
              [class.is-invalid]="joinTheFightForm.get('message').invalid && joinTheFightForm.get('message').touched"></textarea>
          </div>

          <div>
            <label for="submit-button">
            </label>
            <button type="submit">SUBMIT</button>
          </div>

          <p *ngIf="messageSend">Thank you for your message!</p>

        </form>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="section-content">
      <div class="section-partners">
        <div *ngFor="let partner of twikitPartners">
          <a href="{{partner.website}}" target="_blank">
            <img mat-card-image [src]="partner.logo">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="section twikit-info">
    <div class="section-content twikit-info">
      <p>Twikit enables companies to digitally manufacture individualized products on-demand at a large scale.
        <br />
        <br />
      ​</p>
      <div class="text-center-align">
        <p>
          <strong>Contact information</strong><br />
          Twikit NV<br />
          Uitbreidingstraat 60-62<br />
          2600 Berchem<br />
          Belgium<br />
          +32 3 369 34 00<br />
          <a href="http://www.twikit.com">www.twikit.com</a>
        </p>
      </div>
    </div>
    <div class="section-content twikit-footer">
      <span>© 2020 Twikit. All Rights Reserved.</span>
      <div class="footer-socials">
        <a href="https://www.facebook.com/youtwikit" target="_blank" title="Twikit - Facebook">
          <img src="https://www.twikit.com/wp-content/themes/twikit/images/social-facebook.png" alt="Twikit - Facebook">
        </a>
        <a href="https://www.instagram.com/twikit/" target="_blank" title="Twikit - Instagram">
          <img src="https://www.twikit.com/wp-content/themes/twikit/images/social-instagram.png"
            alt="Twikit - Instagram">
        </a>
        <a href="https://twitter.com/youTwikit" target="_blank" title="Twikit - Twitter">
          <img src="https://www.twikit.com/wp-content/themes/twikit/images/social-twitter.png" alt="Twikit - Twitter">
        </a>
        <a href="https://www.linkedin.com/company/twikit" target="_blank" title="Twikit - LinkedIn">
          <img src="https://www.twikit.com/wp-content/themes/twikit/images/social-linkedin.png" alt="Twikit - LinkedIn">
        </a>
      </div>
      <span class="footer-serious">
        <a href="http://www.twikit.com/privacy-policy" target="_blank">Privacy policy</a>
      </span>
    </div>
  </div>
</div>