import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-parameter-card',
  templateUrl: './parameter-card.component.html',
  styleUrls: ['./parameter-card.component.scss']
})
export class ParameterCardComponent implements OnInit {
  @Input() title = 'empty';

  constructor() { }

  ngOnInit() {
  }

}
