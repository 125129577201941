import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';

export interface IProduct {
  name: string;
  image: string;
  description: string;

  available: boolean;
  customizable: boolean;
  downloadable: boolean;
  orderable: boolean;

  // Only required for featured product (uses ngx-gallery).
  galleryOptions?: any[];
  galleryImages?: any[];

  // Only required when customizable.
  showParameters?: boolean;

  // Only required when customizable.
  showIntro?: boolean;
  introMessage?: string;

  // Only required when downloadable.
  downloadMessage?: string;
  smallDownloadMessage?: string;
  downloadDialogTitle?: string;
  downloadDialogContent?: string;

  // Only required when orderable.
  orderMessage?: string;
  smallOrderMessage?: string;
  orderDialogTitle?: string;
  orderDialogContent?: string;

  // Required when downloadable OR orderable.
  osFlow?: string;
  // Required when Twikbot export in order.
  exportUrl?: string;
  // Required when downloadable as asset URL.
  assetUrl?: string;
}

export interface IProducts {
  [key: string]: IProduct;
}

@Injectable()
export class ProductService {

  // Observable string sources
  private activeProduct = new Subject<string>();
  // Observable string streams
  public activeProduct$ = this.activeProduct.asObservable();

  public products: IProducts = {
    // '3D-printed-mask': {
    //   name: '3D Printed face mask',
    //   image: 'https://cdn.twikit.com/projects/covid-19/products/3dprintedmask-wip-dd963102-791b-46f6-acf8-6ae7c718d692/previews/2020-03-27T15:20:33.919Z/3dprintedmask-wip-dd963102-791b-46f6-acf8-6ae7c718d692.png',
    //   description: 'The 3D-printed face mask, co-developed by Twikit and ZiggZagg, is FFP2 certified and protects front-line medical professionals.​<br/><br/>.Its flexible material is validated for sterilization, resulting in an improved fitting and reusability of the mask.​<br/><br/>This product is certified in EU and US and is compatible with replaceable FFP2 certified filters.<br/><br/>The ordering and manufacturing happens through validated partners to guarantee the supply and quality.',

    //   available: true,
    //   customizable: true,
    //   downloadable: false,
    //   orderable: true,

    //   showParameters: false,
    //   showIntro: true,
    //   introMessage: 'This 3D printable face mask is printed in TPU and PA12 material and meets requirements of mask classification FFP2. Please reach out to us by clicking the button below to get in touch with one of our certified manufacturing partners.<br/><br/>This face mask does not serve as a replacement for medical face masks and is intended for personal or any other use only. Twikit makes no warranties, express, implied or otherwise, regarding the accuracy, completeness or performance of this face mask in relation to COVID-19 or any other viruses.',

    //   downloadMessage: '<b>Download</b> production files',
    //   smallDownloadMessage: 'For research and development only.',
    //   downloadDialogTitle: 'Download production files',
    //   downloadDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',

    //   orderMessage: 'Get in touch to order 3D printed masks through a validated manufacturing partner.',
    //   smallOrderMessage: 'Only available for official organizations.',
    //   orderDialogTitle: 'Get in touch with a certified manufacturing partner',
    //   orderDialogContent: 'Fill in your name, organization, address and number of masks you would like to order. We will get in touch with you personally about your order and get you in contact with a certified manufacturing partner.',

    //   osFlow: '3d printed mask',
    //   exportUrl: 'https://cdn.twikit.com/projects/assist3d/products/stekkerhulp-released-85812e83-63e0-4541-b5f2-17ce77051066/descriptors/5560bf32-57ff-4773-867b-83ca8138832e.export.json',
    // },
    'face-shield': {
      name: '3D Printed face shield',
      image: '../../assets/images/face-shield-image-cropped.jpeg',
      description: '',

      galleryOptions: [
        {
          thumbnailsColumns: 3,
          imageAnimation: NgxGalleryAnimation.Slide
        },
        {
        },
        {
          breakpoint: 400,
          width: '100%',
          preview: false
        }
      ],
      galleryImages: [  // Relative to landing-component.css
        {
          small: '../../assets/images/2-face-shield.jpeg',
          medium: '../../assets/images/2-face-shield.jpeg',
          big: '../../assets/images/2-face-shield.jpeg'
        },
        {
          small: '../../assets/images/3-face-shield.jpeg',
          medium: '../../assets/images/3-face-shield.jpeg',
          big: '../../assets/images/3-face-shield.jpeg'
        },
        {
          small: '../../assets/images/1-face-shield.jpeg',
          medium: '../../assets/images/1-face-shield.jpeg',
          big: '../../assets/images/1-face-shield.jpeg'
        },
        {
          small: '../../assets/images/face-shield-render-resized-white.png',
          medium: '../../assets/images/face-shield-render-resized-white.png',
          big: '../../assets/images/face-shield-render-resized-white.png'
        }
      ],

      available: true,
      customizable: false,
      downloadable: true,
      orderable: false,

      downloadDialogTitle: 'Get your 3D printed face shield files',
      downloadDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',
      orderDialogTitle: 'Get your 3D printed face shield files',
      orderDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',

      osFlow: 'face shield',
      assetUrl: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/face-shield/Twikit_FaceShield.zip'
    },
    'mouth-mask': {
      name: 'DIY face mask',
      image: 'https://cdn.twikit.com/projects/covid-19/products/mouthmask-f4513686-dfb0-496c-aad0-e505d22747b3/previews/2020-03-20T07:55:17.809Z/mouthmask-f4513686-dfb0-496c-aad0-e505d22747b3.png',
      description: 'This easy-to-use mask design gives protection for personal use. it can be made out of fabric layers (cotton) and does not need additional sewing.​ <br/><br/>Different sizes are available, after which the custom template can be downloaded for cutting. <br/><br/>Note: This mask is not a medical face mask and is for personal use only.',

      available: true,
      customizable: false,
      downloadable: true,
      orderable: false,

      showParameters: true,
      showIntro: false,

      downloadDialogTitle: 'Download mask patterns',
      downloadDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',

      osFlow: 'mouth mask',
      exportUrl: 'https://cdn.twikit.com/projects/assist3d/products/bocciaballtable-9e43230f-6b14-40a7-b1dc-a75eaa118ed0/descriptors/be447d33-44b0-414b-9ccc-62dfe6dac64a.export.json',
      assetUrl: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/face-mask/DIY_Facemask.zip',
    },
    'space-divider': {
      name: 'Space divider with Plexiglass',
      image: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/space-divider/space-divider.png',
      description: 'Space dividers give additional protection for stores and interaction points.​<br/><br/>A production briefing is included for laser cutting - free to download. ​<br/>Please reach out to us if you need assistance for custom setup or production.​<br/><br/>-​ Easy to install​ <br/><br/>- Dimensions: 100cm x 80cm<br/><br/>- Screen can be made of transparent Plexiglass (thickness of 8mm).<br/><br/>- Construction can be made of different types of wood, like plywood (advised thickness: 20mm)<br/><br/>- For custom measurements please contact us.​',

      available: true,
      customizable: false,
      downloadable: true,
      orderable: false,

      downloadDialogTitle: 'Get your space divider with plexiglass pattern',
      downloadDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',

      osFlow: 'space divider plexi',
      assetUrl: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/space-divider/space-divider.zip'
    },
    'space-divider-foil': {
      name: 'Space divider with foil',
      image: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/space-divider-foil/space-divider-foil.png',
      description: 'Space dividers give additional protection for stores and interaction points.​<br/><br/>A production briefing is included for laser cutting - free to download. ​<br/>Please reach out to us if you need assistance for custom setup or production.​<br/><br/>-​ Easy to install​ <br/><br/>- Dimensions: 100cm x 80cm<br/><br/>- Screen can be made of clear plastic foil.<br/><br/>- Construction can be made of different types of wood, like plywood (advised thickness: 20mm)<br/><br/>- For custom measurements please contact us.​',

      available: true,
      customizable: false,
      downloadable: true,
      orderable: false,

      downloadDialogTitle: 'Get your space divider with foil pattern',
      downloadDialogContent: 'Fill in your name, e-mail address and explain in a short message who you are and why you would like to download the production file for this product.',

      osFlow: 'space divider foil',
      assetUrl: 'https://twikit-cdn.s3-eu-west-1.amazonaws.com/images/corona-initiative/space-divider-foil/space-divider-foil.zip'
    }
  };

  validateProduct(product: IProduct) {
    // TODO
  }

  // Service message commands
  setActiveProduct(key: string) {
    console.log('select product ' + key);
    this.activeProduct.next(name);
  }
}
