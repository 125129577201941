<app-parameter-card [title]="title">
  <div class="container">
  <div class="pill">{{ valueText$ | async }}</div>
  <mat-slider [min]="parameterData.min"
              [max]="parameterData.max"
              [step]="parameterData.step"
              [value]="parameterData.value"
              (input)="value$.next($event.value)"
  ></mat-slider>
  </div>
</app-parameter-card>
