import {Input, OnInit} from '@angular/core';

export class BaseParameterComponent implements OnInit {
  @Input() parameterData: any;
  @Input() updateFn: (value: string|number) => void;

  public title: string;

  constructor() {}

  ngOnInit(): void {
    this.title = this.parameterData.name;

  }
}
