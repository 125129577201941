import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ParameterLoaderService } from '../parameters/parameter-loader.service';
import { NgxTwikitWebPluginService } from '@twikit/ngx-twikit-web-plugin';
import { MatDialog } from '@angular/material/dialog';
import { OrderDialogComponent, IDialogData } from '../../order-dialog/order-dialog.component';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ProductService, IProduct } from 'src/app/product.service';

@Component({
  selector: 'app-product-configurator',
  templateUrl: './product-configurator.component.html',
  styleUrls: ['./product-configurator.component.scss']
})
export class ProductConfiguratorComponent implements AfterViewInit, OnDestroy {

  @ViewChild('visualisation', { static: false }) visualisation: ElementRef;
  @ViewChild('parametercards', { static: false, read: ViewContainerRef }) parametersCards: ViewContainerRef;

  public loaded$ = new BehaviorSubject(false);
  public downloading$ = new BehaviorSubject(false);
  public ordering$ = new BehaviorSubject(false);
  public unsubscribe$ = new Subject<void>();
  private productKey: string;
  public product: IProduct;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly twikbot: NgxTwikitWebPluginService,
    private readonly parameterLoader: ParameterLoaderService,
    private readonly productService: ProductService,
    private readonly dialog: MatDialog) {
    this.productKey = this.route.snapshot.paramMap.get('key');
    this.product = this.productService.products[this.productKey];
  }

  ngAfterViewInit(): void {
    this.twikbot.startConfigurator(this.productKey, this.visualisation.nativeElement)
      .then(context => context)

    this.parameterLoader.setRootViewContainerRef(this.parametersCards);
    this.twikbot.parametersData$
      .pipe(takeUntil(this.unsubscribe$)).subscribe(parameters => {
        this.loaded$.next(true);
        parameters.forEach(p => {
          const component = this.parameterLoader
            .addParameter(p, value => this.twikbot.setValue(p.id, value));
        });
      }
      );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.twikbot.stopCurrentConfigurator();
  }

  public async download() {
    this.downloading$.next(true);
    const saved = await this.twikbot.saveConfigurator();

    const dialogRef = this.dialog.open(OrderDialogComponent, {
      width: '400px',
      data: {
        productName: this.product.name,
        configurationKey: saved.configurationId,
        dialogTitle: this.product.downloadDialogTitle,
        dialogContent: this.product.downloadDialogContent,
        download: true,
        osFlow: this.product.osFlow,
        assetUrl: this.product.assetUrl
      }
    });

    dialogRef.afterClosed()
      .pipe(
        tap(() => this.downloading$.next(false)),
        filter(result => !!result),
        takeUntil(this.unsubscribe$),
      ).subscribe(result => {
      });
  }

  public async order() {
    this.ordering$.next(true);
    const saved = await this.twikbot.saveConfigurator();

    const orderDialogData: IDialogData = {
      productName: this.product.name,
      configurationKey: saved.configurationId,
      dialogTitle: this.product.orderDialogTitle,
      dialogContent: this.product.orderDialogContent,
      download: false,
      osFlow: this.product.osFlow
    };

    const dialogRef = this.dialog.open(OrderDialogComponent, {
      width: '400px',
      data: orderDialogData
    });

    dialogRef.afterClosed()
      .pipe(
        tap(() => this.ordering$.next(false)),
        filter(result => !!result),
        takeUntil(this.unsubscribe$),
      ).subscribe(result => {
      });
  }

}
