import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BaseParameterComponent} from '../base-parameter/base-parameter.component';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {delay, map, tap} from "rxjs/operators";

@Component({
  selector: 'app-icon-picker',
  templateUrl: './slider-basic.component.html',
  styleUrls: [
    './slider-basic.component.scss'
  ]
})
export class SliderBasicComponent extends BaseParameterComponent implements OnInit {
  public valueText$ : Observable<string>;
  public value$: BehaviorSubject<number>;

  ngOnInit(): void {
    super.ngOnInit();

    this.value$ = new BehaviorSubject(this.parameterData.value);


    this.valueText$ =this.value$
      .pipe(
        map(value => this.formatLabel(value)),
      );

    this.value$.subscribe(value => this.updateFn(value));
  }


  public formatLabel(value: number): string{
    return `${value}`;
  };
}
