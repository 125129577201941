import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {TextboxBasicComponent} from './textbox-basic/textbox-basic.component';
import {PickerBasicComponent} from './picker-basic/picker-basic.component';
import {BaseParameterComponent} from './base-parameter/base-parameter.component';
import {SliderBasicComponent} from "./slider-basic/slider-basic.component";
import {DropdownComponent} from "./dropdown/dropdown.component";

@Injectable()
export class ParameterLoaderService {
  private rootViewContianer: ViewContainerRef;
  constructor(private readonly factoryResolver: ComponentFactoryResolver) {
  }

  setRootViewContainerRef(viewerContainerRef: ViewContainerRef) {
    this.rootViewContianer = viewerContainerRef;
  }

  addParameter(parameter: any, updateFn: (value: string) => void): ComponentRef<BaseParameterComponent> {
    const types = {
      'textbox-basic': TextboxBasicComponent,
      'picker-basic': PickerBasicComponent,
      'slider-basic': SliderBasicComponent,
      'dropdown': DropdownComponent,
    };
    const type = types[parameter.template];
    if (!type) {
      console.warn(`type ${parameter.template} not implemented`);
      return;
    }
    const factory = this.factoryResolver.resolveComponentFactory(type);
    const component = factory.create(this.rootViewContianer.parentInjector) as ComponentRef<BaseParameterComponent>;
    component.instance.parameterData = parameter;
    component.instance.updateFn = updateFn;
    this.rootViewContianer.insert(component.hostView);

    return component
  }
}
