<div class="header">
  <a href="/">
    <img src="https://dev-products.twikit.com/assets/images/twikit/logo.svg" />
  </a>
  <mat-divider [vertical]="true"></mat-divider>
  <div class="mat-display-2">{{product.name}}</div>
</div>
<div class="product">
  <div #visualisation class="visualisation">

  </div>
  <div class="sidebar">
    <div class="parameters">
      <div *ngIf="!(loaded$ | async)" class="loading-message">Loading ...</div>
      <div [hidden]="!product.showParameters">
        <ng-container #parametercards></ng-container>
      </div>
      <div *ngIf="product.showIntro">
        <i><p [innerHTML]="product.introMessage"></p></i>
      </div>
      <div *ngIf="product.orderable">
        <mat-divider></mat-divider>
        <p [innerHTML]="product.orderMessage"></p>
        <p><small>{{product.smallOrderMessage}}</small></p>
        <button mat-fab color='primary' (click)="order()" [disabled]="((ordering$ | async) || !(loaded$ | async))">
          <mat-icon>email</mat-icon>
        </button>
      </div>
      <div *ngIf="product.downloadable">
        <p [innerHTML]="product.downloadMessage"></p>
        <p><small>{{product.smallDownloadMessage}}</small></p>
        <button mat-fab color='primary' (click)="download()" [disabled]="((downloading$ | async) || !(loaded$ | async))">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
