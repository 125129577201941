import {Component, OnInit, Output} from '@angular/core';
import {BaseParameterComponent} from '../base-parameter/base-parameter.component';
import {Subject} from "rxjs";

@Component({
  selector: 'app-icon-picker',
  templateUrl: './dropdown.component.html',
  styleUrls: [
    './dropdown.component.scss'
  ]
})
export class DropdownComponent extends BaseParameterComponent implements OnInit {
  private selected: string;


  @Output('factor')
  public factor: Subject<number>;

  ngOnInit(): void {
    super.ngOnInit();

  }

  handle(evt: Event) {
    let value = (evt.target as HTMLSelectElement).value;
    this.updateFn(value);
  }

}
