<div mat-dialog-content class="form">
  <h2 mat-dialog-title>{{dialogData.dialogTitle}}</h2>
  <p>{{dialogData.dialogContent}}<br/><br/></p>
  <form [formGroup]="form">
    <mat-form-field>
      <input matInput id="name" [formControl]="form.controls.name" placeholder="Name" required>
      <div *ngIf="form.controls.name.invalid && (form.controls.name.dirty || form.controls.name.touched)" class="alert alert-danger">
        <mat-error *ngIf="form.controls.name.errors.required">
          Name is required.
        </mat-error>
      </div>
    </mat-form-field>
    <mat-form-field>
      <input matInput id="email" [formControl]="form.controls.email" placeholder="E-mail" required>
      <div *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched)" class="alert alert-danger">
        <mat-error *ngIf="form.controls.email.errors.required">
          E-mail is required.
        </mat-error>
        <mat-error *ngIf="form.controls.email.errors.email">
          Needs to be a valid e-mail address.
        </mat-error>
      </div>
    </mat-form-field>
    <div *ngIf="!dialogData.download">
      <mat-form-field>
        <input matInput id="phone" [formControl]="form.controls.phone" placeholder="Phone number">
      </mat-form-field>
      <mat-form-field>
        <input type="number" min="0" matInput id="orderAmount" [formControl]="form.controls.orderAmount" placeholder="Order amount (desired)" required>
        <div *ngIf="form.controls.orderAmount.invalid && (form.controls.orderAmount.dirty || form.controls.orderAmount.touched)" class="alert alert-danger">
          <mat-error *ngIf="form.controls.orderAmount.errors.required">
            Order amount is required.
          </mat-error>
        </div>
      </mat-form-field>
      <mat-form-field>
        <input matInput id="institution" [formControl]="form.controls.institution" placeholder="Organization" required>
        <div *ngIf="form.controls.institution.invalid && (form.controls.institution.dirty || form.controls.institution.touched)" class="alert alert-danger">
          <mat-error *ngIf="form.controls.institution.errors.required">
            Organization is required.
          </mat-error>
        </div>
      </mat-form-field>
      <mat-form-field>
        <input matInput id="address1" [formControl]="form.controls.address1" placeholder="Street and number" required>
        <div *ngIf="form.controls.address1.invalid && (form.controls.address1.dirty || form.controls.address1.touched)" class="alert alert-danger">
          <mat-error *ngIf="form.controls.address1.errors.required">
            Street and number is required.
          </mat-error>
        </div>
      </mat-form-field>
      <mat-form-field>
        <input matInput id="address2" [formControl]="form.controls.address2" placeholder="Address line 2">
      </mat-form-field>
      <mat-form-field>
        <input matInput id="address3" [formControl]="form.controls.address3" placeholder="Zip code and city" required>
        <div *ngIf="form.controls.address3.invalid && (form.controls.address3.dirty || form.controls.address3.touched)" class="alert alert-danger">
          <mat-error *ngIf="form.controls.address3.errors.required">
            Zip code and city is required.
          </mat-error>
        </div>
      </mat-form-field>
      <mat-form-field>
        <input matInput id="country" [formControl]="form.controls.country" placeholder="Country" required>
        <div *ngIf="form.controls.country.invalid && (form.controls.country.dirty || form.controls.country.touched)" class="alert alert-danger">
          <mat-error *ngIf="form.controls.country.errors.required">
            Country is required.
          </mat-error>
        </div>
      </mat-form-field>
    </div>
    <mat-form-field>
      <textarea matInput id="message" [formControl]="form.controls.message" placeholder="Tell us more about yourself" required></textarea>
      <div *ngIf="form.controls.message.invalid && (form.controls.message.dirty || form.controls.message.touched)" class="alert alert-danger">
      </div>
    </mat-form-field>
  </form>
  <div *ngIf="completed$ | async">
    order has been placed
  </div>
  <mat-error *ngIf="error$ | async">
    An error occurred
  </mat-error>
</div>

<div class="order-buttons" mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button class="order-button" id="order-button" (click)="confirm()" [disabled]="busy$ | async">{{dialogData.download ? 'GET PRODUCTION FILES' : 'GET IN TOUCH'}}</button>
</div>
