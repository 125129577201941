import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BehaviorSubject, of, Subject } from "rxjs";
import { catchError, filter, takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";

export interface IDialogData {
  productName: string;
  configurationKey: string;
  dialogTitle: string;
  dialogContent: string;
  download: boolean;  // Flag to switch between download or order dialog.
  osFlow: string;
  assetUrl?: string;
}

interface IOrderParameters {
  name: string;
  email: string;
  phone?: string;
  message: string;
}

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnDestroy {
  public busy$ = new BehaviorSubject<boolean>(false);
  public completed$ = new Subject<boolean>();
  public error$ = new Subject<any>();
  public unsubscribe$ = new Subject<void>();

  public form = new FormGroup({
    name: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    message: new FormControl('', [])
  });

  constructor(private httpClient: HttpClient,
              public dialogRef: MatDialogRef<OrderDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: IDialogData) {
    if (!this.dialogData.download) {
      this.form.addControl('phone', new FormControl('', []));
      this.form.addControl('orderAmount', new FormControl('', [Validators.required]));
      this.form.addControl('institution', new FormControl('', [Validators.required]));
      this.form.addControl('address1', new FormControl('', [Validators.required]));
      this.form.addControl('address2', new FormControl('', []));
      this.form.addControl('address3', new FormControl('', [Validators.required]));
      this.form.addControl('country', new FormControl('', [Validators.required]));
    }
    this.form.addControl('message', new FormControl('', [Validators.required, Validators.min(1)]));
  }

  private static buildOrderParameters(dialogData: IDialogData, form: FormGroup): IOrderParameters {
    const parameters: IOrderParameters = {
      name: form.get('name').value,  // Required.
      email: form.get('email').value,  // Required.
      phone: form.get('phone') ? form.get('phone').value : '',
      message: form.get('message').value  // Required.
    };

    if (dialogData.download) {
      parameters.message += `<br\><br\><b>Downloaded Product:</b> ${dialogData.productName}`;
      parameters.message += `<br\><br\>Configuration id: ${dialogData.configurationKey}`;
    } else {
      parameters.message += `<br\><br\><b>Ordered Product:</b> ${dialogData.productName}`;
      parameters.message += `<br\><br\>Configuration id: ${dialogData.configurationKey}`;
      parameters.message += `<br\><br\><b>Ordered amount (desired):</b> ${form.get('orderAmount').value}`;  // Required.
      parameters.message += `<br\><br\><b>Organization:</b> ${form.get('institution').value}`;  // Required.
      parameters.message += `<br\><br\><b>Street and number:</b> ${form.get('address1').value}`;  // Required.
      if (form.get('address2').value !== '') {
        parameters.message += `<br\><br\><b>Address line 2:</b> ${form.get('address2').value}`;
      }
      parameters.message += `<br\><br\><b>Zip code and city:</b> ${form.get('address3').value}`;  // Required.
      parameters.message += `<br\><br\><b>Country:</b> ${form.get('country').value}`;  // Required.
    }
    return parameters;
  }

  private static buildOrder(dialogData: IDialogData, parameters: IOrderParameters): any {
    return {
      order: {
        spaceKey: 'covid-19',
        flowKey: dialogData.osFlow,
        thirdPartyOrderId: dialogData.configurationKey,
        updateUrl: 'localhost',
        addresses: [
          {
            addressType: 'e-mail',
            data: {
              email: parameters.email
            },
            purpose: 'instruction'
          }
        ],
        data: {},
        customerInfo: {
          name: parameters.name,
          email: parameters.email,
          phone: parameters.phone,
          message: parameters.message
        },
        orderLines: [
          {
            amountOfUnits: 1,
            data: {},
            deadline: '2025-01-01',
            items: [
              {
                assets: [
                  {
                    id: dialogData.configurationKey,
                    url: dialogData.assetUrl
                  }
                ],
                data: {},
                items: []
              }
            ],
            title: 'internal',
            valuePerUnit: 1
          }
        ]
      }
    }
  }

  public confirm() {
    if (this.form.invalid) return;

    this.error$.next(null);
    this.completed$.next(false);
    this.busy$.next(true);

    // await order
    const parameters: IOrderParameters = OrderDialogComponent.buildOrderParameters(this.dialogData, this.form);
    const order: IOrderParameters = OrderDialogComponent.buildOrder(this.dialogData, parameters);
    this.httpClient.post('https://orders.twikit.com/api/order/create', order)
      .pipe(
        catchError(err => {
          this.error$.next(err);
          this.busy$.next(false);
          return of(null);
        }),
        takeUntil(this.unsubscribe$),
        filter(f => f != null),
      )
      .subscribe((orderData) => {
        this.busy$.next(false);
        this.dialogRef.close(orderData);
      });
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
