import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductService, IProduct, IProducts } from '../product.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { OrderDialogComponent, IDialogData } from '../order-dialog/order-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  public messageSend = false;
  public unsubscribe$ = new Subject<void>();
  public joinTheFightForm;
  public productKeys: string[];
  public products: IProducts;
  public twikitPartners: { name: string, logo: string, website: string }[] = [{
    name: 'Hp',
    logo: 'assets/images/logo-hp.png',
    website: 'https://www.hp.com/'
  }, {
    name: 'ZiggZagg',
    logo: 'https://www.flam3d.be/wp-content/uploads/2018/12/ziggzagg-300x300.jpg',
    website: 'https://www.ziggzagg.be/'
  }, {
    name: 'DSM',
    logo: 'https://www.dsm.com/etc/designs/dsm/img/logo-dsm.svg',
    website: 'https://www.dsm.com/'
  }, {
    name: 'Twikit',
    logo: 'assets/images/twikit-logo.png',
    website: 'https://www.twikit.com/',
  }];
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  private fragment;

  constructor(private formBuilder: FormBuilder,
    private productService: ProductService,
    private route: ActivatedRoute,
    public readonly dialog: MatDialog) {
    this.productKeys = ['mouth-mask', 'space-divider', 'space-divider-foil'];  // All except 3D printed face mask.
    this.products = this.productService.products;
    this.joinTheFightForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      phone: '',
      message: new FormControl('', [
        Validators.required])
    });

    this.galleryOptions = this.products['face-shield'].galleryOptions;
    this.galleryImages = this.products['face-shield'].galleryImages;
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) { }
  }

  downloadProduct(key: string, isOrder: boolean = false) {
    const product: IProduct = this.productService.products[key];
    const orderDialogData: IDialogData = {
      productName: product.name,
      configurationKey: product.name + '-' + Date.now(),
      dialogTitle: product.downloadDialogTitle,
      dialogContent: product.downloadDialogContent,
      download: !isOrder,
      osFlow: product.osFlow,
      assetUrl: product.assetUrl
    };
    this.dialog.open(OrderDialogComponent, {
      width: '400px',
      data: orderDialogData
    });
  }

  contactTwikit(description?: {
    name: string;
    email: string;
    phone: string;
    message: string;
  }) {
    const parameters = {
      name: description.name || '',
      email: description.email || '',
      phone: description.phone || '',
      message: description.message || ''
    };
    fetch('https://wilson-configuration-service.twikit.com/api/v1/configurations/sendemail', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(parameters)
    });
    this.messageSend = true;
    this.joinTheFightForm.reset();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  // public startChatBot(e, t, a): void {
  //   let e = "p";;
  //   let bot_key:"40536d2ba2414bbb";
  //   let welcome_msg = true;
  //   e= {bot_key:"40536d2ba2414bbb",welcome_msg:true,branding_key:"default",server:"https://app.engati.com",e:"p" }
  //   var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.async=!0,n.defer=!0, n.type="text/javascript",n.src=t+"/static/js/chat_widget.js?config="+JSON.stringify(a),c.appendChild(n)}(document,"https://app.engati.com");
  // }
}

