import {Component, OnInit} from '@angular/core';
import {BaseParameterComponent} from '../base-parameter/base-parameter.component';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './picker-basic.component.html',
  styleUrls: [
    './picker-basic.component.scss'
  ]
})
export class PickerBasicComponent extends BaseParameterComponent implements OnInit {
  public selected: string;


  ngOnInit(): void {
    super.ngOnInit();

    this.selected = this.parameterData.value;
  }

  handle(option: string) {
    this.selected = option;
    this.updateFn(option);
  }
}
