import {NgModule} from '@angular/core';
import {Routes, RouterModule, NavigationExtras, ExtraOptions} from '@angular/router';
import {ProductConfiguratorComponent} from './product-configurator/configurator-component/product-configurator.component';
import {LandingComponent} from './landing/landing.component';

const routes: Routes = [
  {path: 'landing', component: LandingComponent},
  {path: 'product/:key', component: ProductConfiguratorComponent},
  {path: '', component: LandingComponent},
  {path: '**', component: LandingComponent},
];

let navigationExtras: ExtraOptions = {
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, navigationExtras)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
