import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProductConfiguratorComponent} from './configurator-component/product-configurator.component';
import {OrderDialogComponent} from '../order-dialog/order-dialog.component';

import {ParameterLoaderService} from './parameters/parameter-loader.service';
import {TextboxBasicComponent} from './parameters/textbox-basic/textbox-basic.component';
import {PickerBasicComponent} from './parameters/picker-basic/picker-basic.component';
import {ParameterCardComponent} from './parameters/parameter-card/parameter-card.component';
import {SliderBasicComponent} from './parameters/slider-basic/slider-basic.component';
import {DropdownComponent} from './parameters/dropdown/dropdown.component';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {ReactiveFormsModule} from '@angular/forms';

import {NgxTwikitWebPluginModule} from '@twikit/ngx-twikit-web-plugin';
import {MatDividerModule} from '@angular/material/divider';

@NgModule({
  declarations: [
    ProductConfiguratorComponent,
    TextboxBasicComponent,
    PickerBasicComponent,
    SliderBasicComponent,
    DropdownComponent,
    ParameterCardComponent,
    OrderDialogComponent,
  ],
  providers: [
    ParameterLoaderService
  ],
  exports: [
    ProductConfiguratorComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatSliderModule,
    MatSelectModule,
    MatDividerModule,
    NgxTwikitWebPluginModule
      .forRoot('https://sites.twikit.com/covid-19/production-2020-04-10-071123676/plugin-script.js'),
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  entryComponents: [
    PickerBasicComponent,
    TextboxBasicComponent,
    SliderBasicComponent,
    DropdownComponent,
    OrderDialogComponent,
  ]
})
export class ProductConfiguratorModule {
}
