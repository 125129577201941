import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {BaseParameterComponent} from '../base-parameter/base-parameter.component';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, filter, map, tap} from 'rxjs/operators';

@Component({
  selector: 'app-text-input',
  templateUrl: './textbox-basic.component.html'
})
export class TextboxBasicComponent extends BaseParameterComponent implements AfterViewInit{
  private values$: Observable<string>;

  @ViewChild('textInput', {static: false})
  private textInput: ElementRef;

  ngAfterViewInit(): void {
    const target = this.textInput.nativeElement as HTMLInputElement;
    let previous = target.value;
    this.values$ = fromEvent(target, 'input')
      .pipe(
        map( evt => target.value),
        map(value => this.transform(value)),
        filter(value => {
          const valid = this.validate(value);
          if (!valid) {
            target.value = previous;
          }
          return valid;
        }),
        tap(value => {
          target.value = value;
          previous = value;
        }),
        debounceTime(100),
      );

    this.values$.subscribe(value => {
      this.updateFn(value);
      target.value = value;
    });
  }

  private transform(value: string): string {
    if (this.parameterData.forceCase === 'upper') {
      value = value.toUpperCase();
    } else if (this.parameterData.forceCase === 'lower') {
      value = value.toLowerCase();
    }
    return value;
  }

  private validate(value: string): boolean {
    const validation = new RegExp(this.parameterData.validationRegex);
    return this.parameterData.maxLength > value.length && validation.test(value);
  }

}
